import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
//import { Iposts } from '../Iposts.interface';
import { Observable } from 'rxjs';
import { Blog } from '../model/blog';
import { environment } from 'src/environments/environment';
import { SystemService } from './system.service';

@Injectable({
    providedIn: 'root'
})
export class PostsService {

    private AllHomeposts: any[]=[];
    private Allposts: any[]=[];


    getCategoryNameById(id: number) {
        throw new Error('Method not implemented.');
    }
    private apiPath: string;


    constructor(private http: HttpClient, public service: SystemService) { this.apiPath = this.service.Settings.Base_API_URL }


    setAllPostCall(data: any[]): void {
        this.Allposts = data;
    }

    getlocalAllPostCall()
    {
        return this.Allposts;
    }

    async geAllPostCall(): Promise<any[]> {
        if (this.Allposts.length > 0) {
            // If the productList is already populated, return it without making an API call
            return this.Allposts;
        } else {
            try {
                // If AllTypelists is empty, make an API call here to fetch the data
                const apiData = await this.service.Data.ExecuteAPI_Get<any[]>("Post");
                // Update AllTypelists with the fetched data
                console.log('Error fetching Post:', apiData);
                this.Allposts = apiData;
                // Return the fetched data
                return apiData;
            } catch (error) {
                // Handle errors here
                console.error('Error fetching Post:', error);
                return []; // or throw the error, depending on your error-handling strategy
            }
        }
    }

    setAllHomePostCall(data: any[]): void {
        this.AllHomeposts = data;
    }

    getlocalHomeAllPostCall()
    {
        return this.AllHomeposts;
    }

    async geAllPostHomeCall(): Promise<any[]> {
        if (this.AllHomeposts.length > 0) {
            // If the productList is already populated, return it without making an API call
            return this.AllHomeposts;
        } else {
            try {
                // If AllTypelists is empty, make an API call here to fetch the data
                const apiData = await this.service.Data.ExecuteAPI_Get<any[]>("Post/random");
                // Update AllTypelists with the fetched data
                console.log('Error fetching Post:', apiData);
                this.AllHomeposts = apiData;
                // Return the fetched data
                return apiData;
            } catch (error) {
                // Handle errors here
                console.error('Error fetching Post:', error);
                return []; // or throw the error, depending on your error-handling strategy
            }
        }
    }








    async addPost(blog: Blog) {
        blog.Id = 0;
        let res = await this.service.Data.ExecuteAPI_Post("Post", blog);
        return res;
    }


    async getPost(id: number): Promise<any> {
        try {
          return await this.service.Data.ExecuteAPI_Get<any>("Post/" + id);
        } catch (error) {
          console.error('Error fetching categories:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }

    getAllPosts(): Observable<Blog[]> {
        return this.http.get<Blog[]>(this.apiPath + '/api/Post');
    }

    getUserPosts(userName: string) {
        return this.http.get<Blog[]>(this.apiPath + '/api/Post/userPost/' + userName);
    }

    async updatePost(blog: Blog): Promise<Observable<Blog>> {
        let res = await this.service.Data.ExecuteAPI_Put("Post",`${blog.Id}?id=${blog.Id}`, blog);
        return res;
        //return this.http.put(this.apiPath + '/api/Post/edit/', { blog });
    }

    deletePost(id: number) {
        return this.http.delete(this.apiPath + '/api/Post/delete/' + id);
    }
}
