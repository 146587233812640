import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EncryptionService } from './encryption.service';
import { AuthLogin } from '../model/authlogin';
import { SystemService } from './system.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private apiPath: string;  sessionExpirationSeconds: number = 60 * 60;
    constructor(private http: HttpClient,
        private encryptionService: EncryptionService, public service: SystemService) { this.apiPath = this.service.Settings.Base_API_URL }

    getAllUsers(): Observable<string[]> {
        return this.http.get<string[]>(this.apiPath + '/api/User');
    }

    async validateUser(authlogin: AuthLogin) {
        try {
          //const response = await this.service.Data.ExecuteAPI_PostJson("api/login", authlogin);
          const response = await this.http.post<AuthResponse>(this.apiPath + '/api/loginuser', authlogin).toPromise();
          localStorage.setItem('authResponse', JSON.stringify(response));
          let expiration_date = new Date();
          expiration_date.setSeconds(expiration_date.getSeconds() + response.expiresIn);
          this.service.App.setCookie("Bearer", response.accessToken, expiration_date,response.refreshToken);
          this.service.Data.SetHttpOptions();
          //console.log('Authentication response:', response);
          return 1;
        } catch (error) {
            return 0;
          console.error('Error during authentication:', error);
          throw error; // Rethrow the error for the caller to handle
        }
      }

      async forgetpassword(email: string): Promise<any> {
        try {
            //const apiUrl = `${this.apiPath}/api/forgotpassword/${encodeURIComponent(email)}`;
            return await this.service.Data.ExecuteAPI_Post('forgot-password', {email: email});
           // return await this.service.Data.ExecuteAPI_Get<any>(this.apiPath + '/api/forgotpassword/'+email);
        } catch (error) {
          console.error('Error fetching categories:', error);
          return null;
        }
      }


    authUser(user: any, UserArray: any[]) {
        return UserArray.find(p => p.userName === user.userName && p.password === this.encryptionService.encryptPass(user.password));
    }
}

export interface AuthResponse {
    tokenType: string;
    accessToken: string;
    expiresIn: number;
    refreshToken: string;
    username: string;
    userEmail: string;
    userId: string;
    // Add other properties as needed
  }
